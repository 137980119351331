import React  from 'react';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
import styles from './table-skeleton-loader.module.css';

const TableSkeletonLoader = ({ numRows = 5, rowHeight = 18 }) => {
    const skeletonRow = () => (
        <tr>
            <td><Skeleton height={rowHeight} /></td>
            <td><Skeleton height={rowHeight} /></td>
            <td><Skeleton height={rowHeight} /></td>
        </tr>
    );

    return (
        <div>
            <div className="d-flex justify-content-between">
                <div className="market-name pr-2">
                    <Skeleton width={100} height={10} />
                </div>
                <div className="update-time pl-2">
                    <Skeleton width={100} height={10} />
                </div>
            </div>
            <table className={`table table-borderless m-0 ${styles.TopListLoader}`}>
                <tbody>
                {Array.from({ length: numRows }, (_, i) => (
                    <React.Fragment key={i}>{skeletonRow()}</React.Fragment>
                ))}
                </tbody>
            </table>
            <div className="text-center">
                <Skeleton width={100} height={8} />
            </div>
        </div>
    );
};

export default TableSkeletonLoader;
