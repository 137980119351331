import React, {useEffect, useRef, useState} from "react";
import Box from "../components/box/Box";
import {Helmet} from "react-helmet";
import * as timeago from 'timeago.js';
import fa from 'timeago.js/lib/lang/fa'
import 'intersection-observer';
import LazyLoad from "react-lazyload";
import dynamic from "next/dynamic";
import TableSkeletonLoader from "../components/loaders/TableSkeletonLoader";

const IranCurrenciesMarketWidget = dynamic(
    () => import('../components/TopListWidgets/IranCurrenciesMarketWidget'),
    {loading: () => getLoader(10), ssr: false}
);

const CryptocurrenciesMarketWidget = dynamic(
    () => import('../components/TopListWidgets/CryptocurrenciesMarketWidget'),
    {loading: () => getLoader(19), ssr: false}
);

const Big3NewsWidget = dynamic(
    () => import('../components/TopListWidgets/Big3NewsWidget'),
    {ssr: false}
);

const SmallNewsWithOnlyTitleAndLastUpdate = dynamic(
    () => import('../components/TopListWidgets/SmallNewsWithOnlyTitleAndLastUpdate'),
    {ssr: false}
);

const IranMetalsMarketWidget = dynamic(
    () => import('../components/TopListWidgets/IranMetalsMarketWidget'),
    {loading: () => getLoader(10), ssr: false}
);

const ForexMarketWidget = dynamic(
    () => import('../components/TopListWidgets/ForexMarketWidget'),
    {loading: () => getLoader(15), ssr: false}
);

const IranFinancialMarketWidget = dynamic(
    () => import('../components/TopListWidgets/IranFinancialMarketWidget'),
    {loading: () => getLoader(9), ssr: false}
);

const EnergyMarketWidget = dynamic(
    () => import('../components/TopListWidgets/EnergyMarketWidget'),
    {loading: () => getLoader(5), ssr: false}
);

const MetalsMarketWidget = dynamic(
    () => import('../components/TopListWidgets/MetalsMarketWidget'),
    {loading: () => getLoader(7), ssr: false}
);

const AgricultureMarketWidget = dynamic(
    () => import('../components/TopListWidgets/AgricultureMarketWidget'),
    {loading: () => getLoader(7), ssr: false}
);


timeago.register('fa', fa);

export default function Home() {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [loadedWidgets, setLoadedWidgets] = useState([]);
    const elementRef = useRef(null);

    useEffect(() => {
        // const handleScroll = () => {
        //     setScrollPosition(window.scrollY);
        // };
        // if (elementRef.current) {
        //     console.log("Element height:", elementRef.current.clientHeight);
        // }
        //
        // window.addEventListener("scroll", handleScroll);
        // return () => {
        //     window.removeEventListener("scroll", handleScroll);
        // };
    }, []);

    function makeLazLoadList(boxes) {
        return boxes.map((BoxComponent, index) => {
            return (
                <LazyLoad
                    key={index}
                    height={100}
                    offset={100}
                    render={() => <BoxComponent loading={true}/>}
                >
                    <BoxComponent loading={false}/>
                </LazyLoad>
            );
        });
    }

    const getNewsBox = () => makeLazLoadList([
        Big3NewsWidget,
        SmallNewsWithOnlyTitleAndLastUpdate
    ]);

    const getPriceBoxes = () => makeLazLoadList([
        IranCurrenciesMarketWidget,
        CryptocurrenciesMarketWidget,
        IranMetalsMarketWidget,
        ForexMarketWidget,
        IranFinancialMarketWidget,
        EnergyMarketWidget,
        MetalsMarketWidget,
        AgricultureMarketWidget,
    ]);

    let metaDescription = 'نرخ‌ لحظه ای، اخبار زنده، نمودار تاریخچه و تحلیل تکنیکال بازارهای ارز، طلا، ارز دیجیتال، فارکس، انرژی و بورس را دریافت کنید. تغیرات قیمت ها را آنی بررسی کنید!';
    let metaTitle = 'بهابین: مرجع آخرین نرخ و اخبار بازارهای مالی';
    return (
        <>
            <Box rwFreeHeight={true} disableHeader={true} loaded={true}
                 leftBoxClassName={'w-100 text-center'}
                 isMainPage={true}
                 height={'auto'}
                 disableAdBox={true}
            >
                <Helmet
                    title={'بهابین: مرجع آخرین نرخ و اخبار فوری بازار'}
                    meta={[
                        {
                            name: 'description',
                            content: metaDescription
                        },{
                            name: 'og:description',
                            content: metaDescription
                        }, {
                            property: 'twitter:description',
                            content: metaDescription
                        }, {
                            property: 'og:title',
                            content: metaTitle
                        }, {
                            property: 'twitter:title',
                            content: metaTitle
                        },
                    ]}
                />
                <div className="row m-0" ref={elementRef}>
                    <aside className="col-12 col-md-4 bg-white pr-0 pt-3">
                        {getPriceBoxes()}
                    </aside>
                    <article className="col-12 col-md-8 bg-white p-0">
                        {getNewsBox()}
                    </article>
                </div>
            </Box>
        </>
    )
}

function getLoader(rows) {
    return <TableSkeletonLoader numRows={rows}/>
}