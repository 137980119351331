import axios from "../plugins/axios";
import {
    addMetadataToForeignMarketResponse,
    makeDataLastUpdateJson,
    parseIranMarketApiResponse
} from "../../util/DominantUtil";

export const refreshStateDataForIranMarkets = async (getter, setter, url) => {
    const existingData = getter();
    if (existingData === null) {
        const { parsedData, lastUpdate } = await fetchDataForIranMarkets(url);
        const data = makeDataLastUpdateJson(parsedData, lastUpdate);

        setter(data);
        return data;
    } else {
        return existingData;
    }
};

export const fetchDataForIranMarkets = async (url) => {
    const response = await axios.get(url);
    const parsedData = parseIranMarketApiResponse(response.data.data);
    const lastUpdate = response.data.lastUpdate;

    return { parsedData, lastUpdate };
};

export const refreshStateDataForeignMarkets = async (getter, setter, url, symbolsCache) => {
    const existingData = getter();
    if (existingData === null) {
        const { parsedData, lastUpdate } = await fetchDataForeignMarkets(url, symbolsCache);
        const data = makeDataLastUpdateJson(parsedData, lastUpdate);

        setter(data);
        return data;
    } else {
        return existingData;
    }
};

const fetchDataForeignMarkets = async (url, symbolsGlobalCache) => {
    const response = await axios.get(url);
    const parsedData = addMetadataToForeignMarketResponse(response.data.data, symbolsGlobalCache)
    const lastUpdate = response.data.lastUpdate;

    return { parsedData, lastUpdate };
};
